@charset "UTF-8";

/* ==========================================================================
   media query
   ======================================================================= */
@mixin for-note-pc {
  @media screen and (max-width: $note-pc-breakpoint) {
    @content;
  }
}
// @mixin for-tab {
//   @media screen and (max-width: $tab-breakpoint) {
//     @content;
//   }
// }
@mixin for-sp {
  @media screen and (max-width: $sp-breakpoint) {
    @content;
  }
}

/* ==========================================================================
   font
   ======================================================================= */
@mixin font-ja {
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 0.1em;
  line-height: 1.5;
  font-feature-settings: "palt";
}
@mixin font-en-ttl {
  font-family: orpheuspro, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.05em;
  line-height: 1.5;
}
@mixin font-en-txt {
  font-family: quasimoda, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01em;
  line-height: 1.5;
}


/* ==========================================================================
   gradient color
   ======================================================================= */
@mixin gradient-color {
  // background: rgb(255,82,142);
  // background: linear-gradient(90deg, rgba(255,82,142,1) 0%, rgba(255,133,44,1) 100%);
  // background: linear-gradient(90deg, #881818 20%, #C43A3A 100%);
  // background: linear-gradient(90deg, rgba(238,47,75,1) 0%, rgba(238,142,49,1) 100%);
  // background: linear-gradient(90deg, rgba(220,40,70,1) 20%, rgba(238,122,19,1) 100%);
  // background: linear-gradient(90deg, rgba(209,5,24,1) 20%, rgba(85,74,78,1) 100%);
  background: linear-gradient(90deg, rgba(196,29,68,1) 20%, rgba(237,108,40,1) 100%);
  background: rgba(196,29,68,1);
}
