@import "./src/assets/scss/prepends.scss";

.page-header {
  position: fixed;
  bottom: calc((-100 + 40)/1920 * 100vw);
  left: calc(25/1920 * 100vw);
  z-index: 10;
  mix-blend-mode: exclusion;
  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    position: fixed;
    top: 0;
    left: calc(140/1920 * 100vw);
    background-color: rgba(255, 255, 255, 0.3);
    @include for-sp(){
      left: calc(100/750 * 100vw);
    }
  }
  &::before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    position: fixed;
    top: 0;
    right: calc(140/1920 * 100vw);
    background-color: rgba(255, 255, 255, 0.3);
    @include for-sp(){
      display: none;
    }
  }
  &__txt {
    @include font-en-ttl;
    font-size: calc(100/1920 * 100vw);
    color: $white;
    // writing-mode: sideways-rl; 
    line-height: 1;
    transform-origin: top left;
    transform: rotate(-90deg);
    @include for-sp(){
      font-size: calc(50/750 * 100vw);
      bottom: calc(110/750 * 100vw);
      left: calc(17/750 * 100vw);
      position: relative;
    }
  } 
}
