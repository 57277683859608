@import "./src/assets/scss/prepends.scss";

.back-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(140/1920 * 100vw);
  height: calc(140/1920 * 100vw);
  z-index: 10;
  @include for-sp(){
    width: calc(100/750 * 100vw);
    height: calc(100/750 * 100vw);
  }
  a {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: $black;
    justify-content: center;
    align-items: center;
    transition: all .6s cubic-bezier(.4,.64,.68,1);
  }
  &__txt {
    font-size: calc(20/1920 * 100vw);
    @include font-en-ttl;
    line-height: 1;
    border-bottom: calc(4/1920 * 100vw) solid $white;
    padding: calc(5/1920 * 100vw);
    color: $white;
    transition: all .6s cubic-bezier(.4,.64,.68,1);
    @include for-sp(){
      font-size: calc(24/750 * 100vw);
    }
  }
}

.no-touchevents {
  a {
    &:hover {
      background-color: $white;
      .back-btn__txt {
        color: $black;
        letter-spacing: 0.3em;
        border-color: $black;
      }
    }
  }

}

