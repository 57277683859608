@charset "UTF-8";

/* ==========================================================================
   breakpoint
   ======================================================================= */
$note-pc-breakpoint: 1440px;
// $tab-breakpoint: 960px;
$sp-breakpoint: 1024px;

/* ==========================================================================
   color
   ======================================================================= */
$white: #ffffff;
$black: #1C1C1F;
$gray: #707070;
// $red: #D1052A;
$red: #C41D44;