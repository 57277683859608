@import "./src/assets/scss/prepends.scss";

.notfound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  &__inner {
    h1 {
      font-size: calc(400/1920 * 100vw);
      text-align: center;
      color: $white;
      @include font-en-ttl;
      line-height: 1;
    }
    p {
      font-size: calc(50/1920 * 100vw);
      text-align: center;
      color: $white;
      @include font-en-ttl;
      line-height: 1;
    }
  }
}

